<template>
  <div id="task02">
    <div class="cm-container">
      <breadcrumb></breadcrumb>
      <div class="nt">实训说明</div>
      <div class="tcontent">
        <div class="t-line">
          <span class="stitle">实训名称：</span>施工进度管理的主要措施
        </div>
        <div class="t-line">
          <span class="stitle">关联课程: </span>《建筑施工组织与管理》
        </div>
        <div class="t-line">
          <span class="stitle">实训须知：</span>
          <br />1.实训有时间限制，单次进入任务到提交任务为实训的时间
          <br />2.超时未提交的记为未完成，需重新进行实训，且未完成的会被记录
        </div>
        <div class="t-line">
          <span class="stitle">指导说明：</span>
          <br />按照实训任务要求，在游戏互动场景中，完成相应的关卡。
        </div>
      </div>
      <div class="nt">实训任务</div>
      <div class="task-content">
        <div class="task-text">在游戏场景中，完成施工进度管理</div>

        <div class="task-t-btn">游戏互动实训（虚拟场景）</div>
        <div class="task-t-list">
          <div class="tasks">
            <div class="task01">
              <span>实训方式：</span>
              <span class="color01">孪生实景实训</span>
            </div>

            <div class="task01">
              <span>考核知识点：</span>
              <span class="color01">2</span>
            </div>

            <div class="task01">
              <span>已考核知识点：</span>
              <span class="color01">0</span>
            </div>

            <div class="task01">
              <span>正确数：</span>
              <span class="color01">-</span>
            </div>

            <div class="task01">
              <span>正确率：</span>
              <span class="color01">-</span>
            </div>
          </div>

          <div class="task02">查看解析</div>
        </div>
        <div class="task-img">
          <div class="task-l">
            <!-- <video src="@/assets/video/v02.mp4" controls></video> -->
          </div>
          <div class="task-r">
            <div @click="done01(1)">正确提示</div>
            <div @click="done01(2)">错误提示</div>
            <div @click="done">完成考核</div>
          </div>
        </div>

        <!-- <div class="task-video">
          <video src="@/assets/video/v02.mp4"
                 controls></video>
        </div> -->
      </div>
    </div>

    <div class="a-box01" v-if="isboxShow01">
      <div class="b-mask"></div>
      <div class="b-content-c">
        <!-- <div class="bcc-title">
          <div class="t-text">实训结果</div>
          <div class="closeIcon" @click="hideb01">&times;</div>
        </div> -->

        <div class="bcc-title01">
          <div class="t-text">
            {{ isboxtitle == 1 ? "操作正确" : "操作错误" }}
          </div>
        </div>
        <div class="bcc-content">
          <div class="ttc" v-if="isboxtitle == 1">
            <div class="fs01">完成知识点 <span class="cl01">1</span>的实训</div>
            <div class="fs01">
              经验值+
              <span class="cl01">10</span>
            </div>
            <div class="fs01">再接再厉～</div>

            <div class="fs-btn" @click="hideb01">继续</div>
          </div>

          <div class="fs012" v-else-if="isboxtitle == 2">
            <div class="fs01">本操作考核知识点：施工进度管理方法类型</div>
            <div class="fs01">正确操作：应该,……</div>
            <div class="ttc">
              <div class="fs01">
                经验值-
                <span class="cl01">5</span>
              </div>
              <div class="fs01">要记住这个知识点，继续努力～</div>
              <div class="fs-btn" @click="hideb01">继续</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="a-box" v-if="isboxShow">
      <div class="b-mask"></div>
      <div class="b-content-c">
        <div class="bcc-title">
          <div class="t-text">实训结果</div>
          <div class="closeIcon" @click="hideb">&times;</div>
        </div>
        <div class="bcc-content">
          <div class="ttc">
            <span class="fs01">实训成绩：</span>
            <span class="fs02 pr">合格</span>
            <span class="fs01">分数：</span>
            <span class="fs03 pr">50分</span>
            <span class="fs01">满分：</span>
            <span class="fs03 pr">100分</span>
            <span class="fs01">试题数：</span>
            <span class="fs03 pr">2</span>
            <span class="fs01">正确数：</span>
            <span class="fs03 pr">1</span>
            <span class="fs01">正确率：</span>
            <span class="fs03 pr">50%</span>
            <span class="fs01">实训次数：</span>
            <span class="fs03 pr">3</span>
            <span class="fs01">本次实训时间：</span>
            <span class="fs03 pr">30分钟</span>
            <span class="fs01">累计实训时间：</span>
            <span class="fs03 pr">100分钟</span>
          </div>
          <div class="bcc">
            <div class="clc">
              <div class="c-title">实训分数曲线</div>
              <div class="c-content" id="c01"></div>
            </div>
            <div class="crc">
              <div class="c-title">实训分数曲线</div>
              <div class="c-content" id="c02"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage } from "@/js/common";
import { makeChart } from "@/js/charts";
import breadcrumb from "@/components/breadcrumb";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      isboxShow: false,
      isboxShow01: false,
      isboxtitle: 1, //正确1：错误：2
    });
    const methods = {
      // 正确错误弹框
      hideb01: () => {
        state.isboxShow01 = false;
      },
      done01: (id) => {
        state.isboxShow01 = true;
        state.isboxtitle = id;
      },
      hideb: () => {
        state.isboxShow = false;
        router.go(-2);
      },
      done: () => {
        state.isboxShow = true;
        nextTick(() => {
          initc01();
          initc02();
        });
      },
      back: (item) => {
        console.log(item);
        if (item == 1) {
          store.commit("SetnarbarMenuActive", "3");
          setStorage("narbarMenuActive", "3");
          router.push("/home/digitalDesign");
        } else if (item == 2) {
          router.go(-1);
        }
      },
    };
    const initc01 = function () {
      let data = [
        {
          name: "1",
          value: 50,
        },
        {
          name: "2",
          value: 50,
        },
        {
          name: "3",
          value: 50,
        },
      ];
      let xAxisData = data.map((v) => v.name);
      let yAxisData = data.map((v) => v.value);
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let html = "";
            params.forEach((v) => {
              console.log(v);
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                ${v.name}
                <span style="color:#000;font-weight:700;font-size: 18px">${v.value}</span>分`;
            });

            return html;
          },
          extraCssText:
            "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
        },
        grid: {
          top: 60,
          left: 20,
          right: 20,
          bottom: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#3C3C3C",
              fontSize: 12,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: xAxisData,
        },
        yAxis: {
          type: "value",
          name: "分数",
          min: 0,
          max: 100,
          axisLabel: {
            textStyle: {
              color: "#C6C6C6",
              fontSize: 12,
            },
          },
          nameTextStyle: {
            color: "#C6C6C6",
            fontSize: 12,
            lineHeight: 40,
            padding: [0, 0, 0, -30],
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#eee",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          minInterval: 25,
        },
        series: {
          symbolSize: 5,
          color: "#059DF7",
          symbol: "circle",
          itemStyle: {
            normal: {
              color: "#fff",
              borderColor: "#059DF7",
              borderWidth: 1,
            },
          },
          // smooth: true,
          // showSymbol: false,
          name: "2019",
          type: "line",
          zlevel: 3,
          lineStyle: {
            normal: {
              color: "rgba(10, 179, 255,0.8)",
            },
          },

          data: yAxisData,
        },
      };
      makeChart("c01", option);
    };
    const initc02 = function () {
      let data = [
        {
          name: "1",
          value: 50,
        },
        {
          name: "2",
          value: 50,
        },
        {
          name: "3",
          value: 50,
        },
      ];
      let xAxisData = data.map((v) => v.name);
      let yAxisData = data.map((v) => v.value);
      let option = {
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let html = "";
            params.forEach((v) => {
              console.log(v);
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                ${v.name}
                <span style="color:#000;font-weight:700;font-size: 18px">${v.value}</span>%`;
            });

            return html;
          },
          extraCssText:
            "background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;",
        },
        grid: {
          top: 60,
          left: 20,
          right: 20,
          bottom: 30,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            formatter: "{value}",
            textStyle: {
              color: "#3C3C3C",
              fontSize: 12,
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: xAxisData,
        },
        yAxis: {
          type: "value",
          name: "正确率",
          min: 0,
          max: 100,
          axisLabel: {
            textStyle: {
              color: "#C6C6C6",
              fontSize: 12,
            },
          },
          nameTextStyle: {
            color: "#C6C6C6",
            fontSize: 12,
            lineHeight: 40,
            padding: [0, 0, 0, -30],
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#eee",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          minInterval: 25,
        },
        series: {
          symbolSize: 5,
          color: "#059DF7",
          symbol: "circle",
          itemStyle: {
            normal: {
              color: "#fff",
              borderColor: "#059DF7",
              borderWidth: 1,
            },
          },
          // smooth: true,
          // showSymbol: false,
          name: "2019",
          type: "line",
          zlevel: 3,
          lineStyle: {
            normal: {
              color: "rgba(10, 179, 255,0.8)",
            },
          },

          data: yAxisData,
        },
      };
      makeChart("c02", option);
    };
    onMounted(() => {});
    onUnmounted(() => {});
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: { breadcrumb },
};
</script>
<style lang="scss" scoped>
#task02 {
  height: 100%;
  position: relative;
   .fs01 {
    color: rgba(51, 51, 51, 1);
  }
  .cl01 {
    color: rgba(0, 159, 254, 1);
  }
  .fs02 {
    color: #2ace5c;
  }
   .a-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    .b-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .b-content-c {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 685px;
      height: 367px;
      background: #fff;
      box-shadow: 0px 0px 7px #00000057;
      .bcc-title {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        .closeIcon {
          font-size: 30px;
          cursor: pointer;
          color: #979797;
          font-weight: normal;
        }
      }
      .bcc-content {
        .ttc {
          padding: 10px 20px 0;
          font-size: 14px;
          span {
            padding: 0 0 10px;
            display: inline-block;
          }
          .pr {
            padding: 0 30px 10px 0;
          }
        }
        .bcc {
          width: 100%;
          height: 250px;
          display: flex;
          .c-title {
            font-size: 14px;
            color: #3c3c3c;
            padding: 5px 20px 5px;
          }
          .c-content {
            flex-grow: 1;
          }
          .clc {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .crc {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  .a-box01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    .fs-btn {
      width: 28px;
      height: 19px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 19px;
    }
    .b-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .b-content-c {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 500px;
      height: 236px;
      background: #fff;
      box-shadow: 0px 0px 7px #00000057;
      .bcc-title01 {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: center;
        .closeIcon {
          font-size: 30px;
          cursor: pointer;
          color: #979797;
          font-weight: normal;
        }
      }
      .bcc-title {
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        box-sizing: border-box;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: space-between;
        .closeIcon {
          font-size: 30px;
          cursor: pointer;
          color: #979797;
          font-weight: normal;
        }
      }
      .bcc-content {
        .fs012{
          padding: 20px;
          box-sizing: border-box;
                    
          color: #333333;
          font-size: 14px;
        }
        .ttc {
          padding: 10px 20px 0;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            padding: 0 0 10px;
            display: inline-block;
          }
          .pr {
            padding: 0 30px 10px 0;
          }
        }

        .fs-btn {
          padding: 6px 20px;
          background: #059df7;
          border: 1px solid #059df7;
          color: #fff;
          font-size: 14px;
          position: absolute;
          bottom: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
  }
  .nt {
    font-size: 15px;
    font-weight: bold;
    color: #555555;
    margin: 20px 0 15px;
  }
  .tcontent {
    background: #fff;
    padding: 20px;
    .t-line {
      font-size: 14px;
      color: #333333;
      &:last-child {
        margin: 20px 0 0;
      }
      .stitle {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .task-content {
    background: #fff;
    padding: 20px;
    .task-t-list {
      width: 70%;
      margin-top: 2px;
      display: flex;
      height: 50px;
      padding: 0 20px;
      border: 1px solid #979797;
      align-items: center;
      justify-content: space-between;
      margin-left: 15%;
      .tasks {
        display: flex;
      }
      .task01 {
        font-size: 14px;
        font-family: MicrosoftYaHei;

        color: #999999;
        .color01 {
          color: #3c3c3c;
          margin-right: 20px;
        }
      }
      .task02 {
        width: 96px;
        height: 30px;
        background: #059df7;
        border: 1px solid #059df7;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
      }
    }
    .task-text {
      font-size: 14px;
      color: #555555;
    }
    .task-t-btn {
      height: 40px;
      background: #059df7;
      line-height: 40px;
      font-size: 15px;
      text-align: center;
      color: #ffffff;
      margin: 20px 0 0;
    }
    .task-img {
      width: 70%;
      display: flex;
      align-items: center;
      margin-left: 15%;
      box-sizing: border-box;
      .task-l {
        width: 70%;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .task-r {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
          width: 96px;
          height: 30px;
          background: #059df7;
          border: 1px solid #059df7;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          text-align: center;
          line-height: 30px;
          margin-bottom: 30px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      // img {
      //   width: 100%;
      //   height: 100%;
      //   object-fit: contain;
      // }
    }
    .task-text {
      font-size: 14px;
      color: #555555;
    }
    .task-video {
      width: 662px;
      height: 468px;
      margin: 20px auto 90px;
      background: chocolate;
      position: relative;
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #task02 {
    .cm-container {
      width: 1400px;
      margin: 0 auto;
      background: #f3f3f3;
      padding: 0 0 100px;
    }
  }
}
</style>
