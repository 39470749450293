<template>
  <div id="breadcrumb" class="clear_f">
    <div class="breadcrumb" v-if = "bclist && bclist.length">
      <div class="c-c" v-for = "(item,index) in bclist" :key = "index">
        <span @click="back(item,index)">{{item.text}}</span>
        <img src="@/assets/img/common/icon03.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      bclist: computed(() => {
        let arr = [
          {
            text: "教学广场",
            nindex: "1",
            path: "/home/index",
            back:false
          }
        ]
        if(store.state.bclist&&store.state.bclist.length&&store.state.bclist[0].text=='教学广场'){

          return store.state.bclist
        }else{
           return arr.concat(store.state.bclist);
        }
       
      }),
    });
    const setbclist = (arr)=>{
      store.commit("Setbclist", arr);
      setStorage("bclist", arr);
    }
    const methods = {
      back:(item,index)=>{
        console.log(item,39)
        if(item.path){
          store.commit("SetnarbarMenuActive", item.nindex);
          setStorage("narbarMenuActive", item.nindex);
          router.push(item.path);
        }else if(item.back){
          let arr = state.bclist.slice(0,index+1);
          arr[arr.length-1].back = false;
          setbclist(arr);
          router.go(-1);
        }
      }
    };
    const initsession = () => { // 如果刷新了页面 从session 中获取 缓存的数据
      let bclist = getStorage("bclist");
      if (bclist) {
        store.commit("Setbclist", bclist);
      }
    };
    onMounted(() => {
      initsession();
    });
    onUnmounted(() => {});
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  components: {},
};
</script> 
<style lang="scss" scoped>
#breadcrumb {
  .breadcrumb {
    display: flex;
    height: 19px;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    margin: 9px 0 12px;
    .c-c {
      &:last-child {
        span{
          cursor: default;
        }
        img {
          display: none;
        }
      }
      &:first-child {
        span {
          padding: 0 8px 0 0;
        }
      }
    }
    span {
      cursor: pointer;
      padding: 0 8px 0 6px;
    }
  }
}
</style>

